import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const Careers = () => {
	return (
		<main className='career'>
			<Helmet>
				<title>Careers - Horizon Market Strategies</title>
				<meta
					name='description'
					content='Horizon Market Strategies (HMS), your trusted partner in delivering bespoke market research solutions aimed at equipping businesses with actionable insights.'
				/>
				<meta
					property='og:title'
					content='Home - Horizon Market Strategies'
				/>
				<meta
					property='og:description'
					content='Horizon Market Strategies (HMS), your trusted partner in delivering bespoke market research solutions aimed at equipping businesses with actionable insights.'
				/>
				<meta
					property='og:site_name'
					content='Horizon Market Strategies'
				/>
			</Helmet>
			<div className='career__Container'>
				{/* DETAILS */}
				<div className='career__Top'>
					<h1>
						{/* Join our Team at <span>Horizon Market Strategies</span> */}
						Join our <span>Team</span>
					</h1>
				</div>
				{/* MORE DETAILS One */}
				<div className='career__Bottom'>
					<h2>Why Choose Us?</h2>
					<div className="text-[1.8rem] font-[500] text-[#042E3E] flex items-center justify-between max-lg:flex-col">
						<div className="w-1/2 max-lg:w-full flex justify-center items-center">
							{/* <img src='/rb_7564.png' alt='career' className='h-[34rem]' /> */}
							<img src='/19466285_6134170.webp' alt='career' className='h-[34rem]' />
						</div>
						<div className="w-1/2 max-lg:w-full">
							<ul className='list-disc pl-6 space-y-6 reports__BulletPoints'>
								<li>
									<strong>Innovative Culture:</strong> Join a team that's pioneering the future of technology with a
									harmonious blend of artificial and human intelligence.
								</li>
								<li>
									<strong>Collaborative Environment:</strong> Work with experts who share your passion for innovation.
								</li>
								<li>
									<strong>Growth Opportunities:</strong> Develop your skills and advance your career with us.
								</li>
								<li>
									<strong>Work-Life Balance:</strong> Enjoy a healthy balance between work and personal life.
								</li>
							</ul>
							{/* <div className='career__Line'>
								<p>
									Innovative Culture:{' '}
									<span>
										Join a team that's pioneering the future of technology with a
										harmonious blend of artificial and human intelligence.
									</span>
								</p>
							</div>
							<div className='career__Line'>
								<p>
									Collaborative Environment:{' '}
									<span>
										Work with experts who share your passion for innovation.
									</span>
								</p>
							</div>
							<div className='career__Line'>
								<p>
									Growth Opportunities:{' '}
									<span>
										Develop your skills and advance your career with us.
									</span>
								</p>
							</div>
							<div className='career__Line'>
								<p>
									Work-Life Balance:{' '}
									<span>
										Enjoy a healthy balance between work and personal life.
									</span>
								</p>
							</div> */}
						</div>
					</div>
				</div>
				{/* MORE DETAILS Two */}
				<div className='career__Bottom'>
					<h2>What to Expect</h2>
					<div className="text-[1.8rem] font-[500] text-[#042E3E] flex items-center justify-between max-lg:flex-col">
						<div className="w-1/2 max-lg:w-full">
							<ul className='list-disc pl-6 space-y-6 reports__BulletPoints'>
								<li>
									<strong>Competitive Salary and Benefits:</strong> We offer a comprehensive package to support your wellbeing.
								</li>
								<li>
									<strong>Professional Development:</strong> Grow your skills through training, mentorship, and opportunities
								</li>
								<li>
									<strong>Dynamic Work Environment:</strong> Collaborate with a talented team in a vibrant setting.
								</li>
								<li>
									<strong>Recognition and Rewards:</strong> Be recognized for your contributions and achievements.
								</li>
							</ul>
							{/* <div className='career__Line'>
								<p>
									Competitive Salary and Benefits:{' '}
									<span>
										We offer a comprehensive package to support your wellbeing.
									</span>
								</p>
							</div>
							<div className='career__Line'>
								<p>
									Professional Development:{' '}
									<span>
										Grow your skills through training, mentorship, and opportunities
									</span>
								</p>
							</div>
							<div className='career__Line'>
								<p>
									Dynamic Work Environment: {' '}
									<span>
										Collaborate with a talented team in a vibrant setting.
									</span>
								</p>
							</div>
							<div className='career__Line'>
								<p>
									Recognition and Rewards:{' '}
									<span>
										Be recognized for your contributions and achievements.
									</span>
								</p>
							</div> */}
						</div>
						<div className="w-1/2 flex justify-center items-center max-lg:w-full">
							<img src='/rb_2060.webp' alt='career' className='h-[34rem]' />
						</div>
					</div>
				</div>
				{/* Apply */}
				<div className="career__Bottom">
					<h2>How to Apply</h2>
					<div className="text-[1.8rem] font-[500] text-[#042E3E] flex items-center justify-between max-lg:flex-col">
						<div className="w-1/2 max-lg:w-full flex justify-center items-center">
							<img src='/rb_52728.webp' alt='career' className='h-[34rem]' />
						</div>
						<div className="w-1/2 max-lg:w-full">
							<p
								className="text-[1.8rem] font-[400] text-[#042E3E]"
							>Follow our official <Link to={"https://www.linkedin.com/company/horizon-market-strategies/posts/?feedView=all"} className="text-blue-500 visited:text-[#800080] active:text-[#FF0000] hover:underline underline-offset-2 font-semibold" target="_blank">LinkedIn page</Link>, or mail your updated CV to{' '} <a href='mailto:hrd@horizon-markets.com' className="text-blue-500 visited:text-[#800080] active:text-[#FF0000] hover:underline underline-offset-2 font-semibold">hrd@horizon-markets.com</a> (mention
								your desired position in the subject line). We will be happy to connect with you.
							</p>
						</div>
					</div>
				</div>
				<div className="career__Bottom">
					<h2>Diversity, Equity, and Inclusion</h2>
					<div className="text-[1.8rem] font-[500] text-[#042E3E] flex items-center justify-between max-lg:flex-col">
						<div className="w-1/2 max-lg:w-full">
							<p
								className="text-[1.8rem] font-[400] text-[#042E3E]"
							>At Horizon Market Strategies, we're committed to creating a diverse, equitable, and inclusive
								workplace. We welcome applications from candidates of all backgrounds and perspectives.
							</p>
						</div>
						<div className="w-1/2 max-lg:w-full flex justify-center items-center">
							<img src='/rb_4112.webp' alt='career' className='h-[34rem]' />
						</div>
					</div>
				</div>
			</div>
		</main>
	);
};

export default Careers;
