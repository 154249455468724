import Button from '../Button/Button.component';
import { Link } from 'react-router-dom';

const Banner = ({ header, info, path, btnText }) => {
	return (
		<section className='banner'>
			<div className='banner__Container '>
				<div className='banner__Left'>
					<h1>{header}</h1>
					<p>
						{info}
					</p>
				</div>
				<div className='banner__Right'>
					<Link to={path}>
						<Button
							text={btnText}
							// className='button__BannerCommon max-lg:rounded-[0.2rem] border border-white bg-transparent font-normal shadow-sm shadow-white hover:shadow-none focus:shadow-none'
							className='rounded-xl w-[26.7rem] px-[1rem] py-[1.5rem] max-lg:rounded-[0.2rem] border border-[#F4F4F4] text-[#F4F4F4] text-[16px] font-[400] bg-[#052F3F] shadow-sm shadow-white hover:shadow-none focus:shadow-none hover:border-[#052F3F] hover:text-[#052F3F] hover:bg-[#F4F4F4]'
						/>
					</Link>
				</div>
			</div>
		</section>
	);
};

export default Banner;
