import React, {
	forwardRef,
	useEffect,
	useState,
} from 'react';
import { fetchReport } from '../../store/reducers/report';
import { selectReport } from '../../store/selector/report';
import {
	Accordion,
	Hero,
	Banner,
	CardHome,
	CardServiceHome,
	Button,
	CardReportsHome,
	Loader,
} from '../../components';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import 'swiper/css/navigation';
import 'swiper/css';
import 'swiper/css/pagination';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import data from '../../constants/static.json';
import { apiClient } from '../../repositories/apiclient';
import { CardTestimonialHome, CardWhyChooseUsHome } from '../../components/Card/Card.component';

const Home = forwardRef((props, ref) => {
	// const AccordionData = data.AccordionData;
	const [tab, setTab] = useState('tab-1');
	const [reports, setReports] = useState([]);
	const [dynamicData, setDynamicData] = useState({});
	const [testimonials, setTestimonials] = useState([]);
	const navigate = useNavigate();

	const fetchLatestReports = async () => {
		apiClient.get('/research-documents?sort=publishDate:desc&pagination[page]=1&pagination[pageSize]=6&populate=*')
			.then((res) => {
				const latestReports = res.data.data;
				setReports(latestReports.sort((a, b) => new Date(b.publishDate) - new Date(a.publishDate)));
			})
			.catch((err) => {
				// console.log(err);
			})
	}

	const fetchDynamicData = async () => {
		apiClient.get('/home-page?populate[Hero][populate]=*&populate[featuredServicesCard][populate]=*')
			.then((res) => {
				console.log("HERE")
				console.log(res.data.data)
				setDynamicData(res.data.data)
			})
			.catch(err => {
				console.log(err)
			})
	}

	useEffect(() => {
		fetchLatestReports();
		fetchDynamicData();
		setTestimonials(data.TestimonialData);
	}, []);

	return (
		<main className='home'>
			<Helmet>
				<title>Home - Horizon Market Strategies</title>
				<meta
					name='description'
					content='Horizon Market Strategies (HMS), your trusted partner in delivering bespoke market research solutions aimed at equipping businesses with actionable insights.'
				/>
				<meta
					property='og:title'
					content='Home - Horizon Market Strategies'
				/>
				<meta
					property='og:description'
					content='Horizon Market Strategies (HMS), your trusted partner in delivering bespoke market research solutions aimed at equipping businesses with actionable insights.'
				/>
				<meta
					property='og:site_name'
					content='Horizon Market Strategies'
				/>
				<script type="application/ld+json">
					{JSON.stringify({
						"@context": "https://schema.org",
						"@type": "Corporation",
						"name": "Horizon Market Strategies",
						"url": "https://horizon-markets.com/",
						"logo": "https://horizon-markets.com/HMSLogo.png",
						"alternateName": "HMS",
						"sameAs": [
							"https://www.linkedin.com/company/horizon-market-strategies/",
							"https://www.youtube.com/@Horizon-Market-Strategies",
							"https://x.com/HorizonMarkets"
						],
						"contactPoint": [
							{
								"@type": "ContactPoint",
								"telephone": "+13473542413",
								"contactType": "sales",
								"areaServed": ["US", "CA"],
								"availableLanguage": "en"
							},
							{
								"@type": "ContactPoint",
								"telephone": "+442037695086",
								"contactType": "sales",
								"areaServed": "GB",
								"availableLanguage": "en"
							},
							{
								"@type": "ContactPoint",
								"telephone": "+918134945168",
								"contactType": "sales",
								"areaServed": "IN",
								"availableLanguage": "en"
							},
							{
								"@type": "ContactPoint",
								"contactType": "customer service",
								"email": "inquiry@horizon-markets.com",
								"availableLanguage": "en"
							}
						]
					})}
				</script>
			</Helmet>
			{
				(dynamicData?.Hero && dynamicData?.heroButtonText) && <Hero data={dynamicData?.Hero} buttonText={dynamicData?.heroButtonText} />
			}
			<section className='home__Reports'
			>
				<div className='home__Reports--Top'>
					<div className='home__Reports--Left'>
						<h1>
							Latest <span>Reports</span>
						</h1>
					</div>
					{/* <div className='home__Reports--Right max-lg:hidden'>
						<Button
							text={dynamicData?.latestReportsButtonText ?? 'See All'}
							className='button__Common2 font-normal shadow-sm shadow-black hover:shadow-none focus:shadow-none'
							onClick={() => { navigate('/reports') }}
						/>
					</div> */}
				</div>

				{reports ? (
					<Swiper
						autoplay={{
							delay: 4000,
							disableOnInteraction: false,
							pauseOnMouseEnter: true
						}}
						navigation={true}
						modules={[Navigation, Autoplay, Pagination]}
						// slidesPerView={3}
						spaceBetween={32}
						breakpoints={{
							640: {
								slidesPerView: 1.5,
								spaceBetween: 20,
							},
							768: {
								slidesPerView: 2,
								spaceBetween: 10,
							},
							1024: {
								slidesPerView: 3,
								spaceBetween: 50,
							},
						}}
						pagination={{ clickable: true }}
						color='#042E3E'
						style={{
							"--swiper-pagination-color": "#042E3E",
							"--swiper-navigation-color": "#042E3E",
						}}
					>
						{reports?.map((item) => (
							<SwiperSlide key={item.id}>
								<CardReportsHome data={item} />
							</SwiperSlide>
						))}
					</Swiper>
				) : null}

				<div className='w-full flex items-end justify-end mt-10'>
					<Button
						text={dynamicData?.latestReportsButtonText ?? 'See All'}
						className='border-[1px] w-[26.7rem] text-[16px] px-[1rem] py-[1.5rem] font-[400] shadow-sm shadow-black hover:shadow-none focus:shadow-none max-lg:w-full text-[#F4F4F4] bg-[#052F3F] border-[#052F3F] hover:border-[#052F3F] hover:text-[#052F3F] hover:bg-[#F4F4F4] rounded-xl'
						onClick={() => { navigate('/reports') }}
					/>
				</div>
			</section>

			<section className='home__byor'>
				<section className='home__Banner'>
					<Banner
						header={dynamicData?.byorH2Text ?? 'Build Your Own Report'}
						info={dynamicData?.byorH3Text ?? 'Your Report in Your Own Unique Way'}
						path='/byor'
						btnText={dynamicData?.byorButtonText ?? 'Build Now'}
					/>
				</section>
			</section>
			{/* WORKFLOW */}
			{/* <div className='hidden max-lg:flex max-lg:flex-col my-[5rem] gap-[1.5rem]'>
				<p className='text-[#042E3E] text-[1.6rem] font-[700] text-center uppercase'>
					Workflow
				</p>
				<h1 className='text-[#042E3E] text-[4rem] font-[700] capitalize text-center'>
					Key benefits
				</h1>
			</div>
			<section className='home__Workflow'>
				<div className='home__Workflow--Left'>
					<div className='home__Workflow--Top max-lg:hidden'>
						<p>Workflow</p>
						<h1>
							Key <span>benefits</span>
						</h1>
					</div>
					<div className='home__Workflow__Middle'>
						<div className='home__WorkflowTabs'>
							<ul>
								<li
									onClick={() => setTab('tab-1')}
									className={
										tab === 'tab-1'
											? 'active__Tab'
											: 'default__tab'
									}>
									Competitive Edge
								</li>
								{tab === 'tab-1' && (
									<div className='home__Workflow--TabData hidden max-lg:block w-full'>
										Stay ahead of the curve. Our research
										provides the intelligence you need to
										outmaneuver your competition.
									</div>
								)}
								<li
									onClick={() => setTab('tab-2')}
									className={
										tab === 'tab-2'
											? 'active__Tab'
											: 'default__tab'
									}>
									Minimize risk
								</li>
								{tab === 'tab-2' && (
									<div className='home__Workflow--TabData hidden max-lg:block w-full'>
										Make informed decisions with confidence.
										Our research helps you identify
										potential roadblocks and maximize the
										return on your investments.
									</div>
								)}
								<li
									onClick={() => setTab('tab-3')}
									className={
										tab === 'tab-3'
											? 'active__Tab'
											: 'default__tab'
									}>
									Focus
								</li>
								{tab === 'tab-3' && (
									<div className='home__Workflow--TabData hidden max-lg:block w-full'>
										Let us handle the research. You can
										focus on what you do best - running your
										business.
									</div>
								)}
							</ul>
							{tab === 'tab-1' && (
								<div className='home__Workflow--TabData block max-lg:hidden'>
									Stay ahead of the curve. Our research
									provides the intelligence you need to
									outmaneuver your competition.
								</div>
							)}
							{tab === 'tab-2' && (
								<div className='home__Workflow--TabData block max-lg:hidden'>
									Make informed decisions with confidence.
									Our research helps you identify potential
									roadblocks and maximize the return on your
									investments.
								</div>
							)}
							{tab === 'tab-3' && (
								<div className='home__Workflow--TabData block max-lg:hidden'>
									Let us handle the research. You can focus
									on what you do best - running your
									business.
								</div>
							)}
						</div>
					</div>
					<div className='mt-[6rem] max-lg:flex max-lg:items-center max-lg:justify-center'>
						<Link to='/contact-us'>
							<Button
								className='button__Common max-lg:rounded-[0.2rem]'
								text='Free Consultation'
							/>
						</Link>
					</div>
				</div>
				<div className='home__Workflow--Right'>
					<img src='/HomeWorkflowImg.svg' alt='Workflow' />
				</div>
			</section> */}
			{/* Featured Services */}
			<section className='home__Services'>
				<div className='home__Services--Top'>
					{/* <p>SERVICES</p> */}
					<h1>
						{
							dynamicData?.featuredServicesHeading ? (
								<>{dynamicData?.featuredServicesHeading.split(' ').map((word, i) => i === 1 ? <span key={i}>{word} </span> : word + ' ')}</>
							) : (
								<>Featured <span>Services</span></>
							)
						}
					</h1>
				</div>
				<div className='home__Services--Bottom'>
					<Swiper
						autoplay={{
							delay: 2500,
							disableOnInteraction: true,
						}}
						breakpoints={{
							640: {
								slidesPerView: 1.5,
								spaceBetween: 20,
							},
							768: {
								slidesPerView: 2,
								spaceBetween: 10,
							},
							1024: {
								slidesPerView: 3.5,
								spaceBetween: 40,
							},
						}}
						modules={[Autoplay, Pagination]}
						spaceBetween={30}
						pagination={{ clickable: true }}>
						{/* {data.HomeServiceCardData.map((item) => ( */}
						{dynamicData?.featuredServicesCard?.map((item) => (
							<SwiperSlide
								key={item.id}
								className='min-h-[40rem] bg-[#F4F4F4] rounded-[2rem] p-[3rem] flex flex-col gap-[4rem] items-start '>
								<CardServiceHome data={item} />
							</SwiperSlide>
						))}
					</Swiper>
				</div>
			</section>
			{/* Why Choose Us */}
			{/* <section className='home__Wcu'>
				<h1>
					Why <span>choose us?</span>
				</h1> */}
			{/* <div className='home__Wcu--Cards max-lg:hidden'>
					{data.HomeCardOneData.map((item) => (
						<CardHome data={item} key={item.id} />
					))}
				</div> */}
			{/* Slider For Why Choose Us - Responsive View */}
			{/* <div className='home__WcuSlider hidden max-xl:block'>
					<Swiper
						autoplay={{
							delay: 2500,
							disableOnInteraction: false,
						}}
						breakpoints={{
							640: {
								slidesPerView: 1.5,
								spaceBetween: 20,
							},
							768: {
								slidesPerView: 3,
								spaceBetween: 10,
							},
							1024: {
								slidesPerView: 3.5,
								spaceBetween: 50,
							},
						}}
						modules={[Autoplay]}
						spaceBetween={30}
						pagination={{ clickable: true }}>
						{data.HomeCardOneData.map((item) => (
							<SwiperSlide
								key={item.id}
								className='min-h-[40rem] flex flex-col gap-[4rem] items-start '>
								{<CardHome data={item} key={item.id} />}
							</SwiperSlide>
						))}
					</Swiper>
				</div> */}
			{/* <div className='max-xl:block'>
					<div>
						{data.HomeCardOneData.map((item, index) => (
							<CardWhyChooseUsHome data={item} flip={index % 2 === 0} key={item.id} />
						))}
					</div>
				</div> */}
			{/* </section> */}
			{/* Banner */}
			<section className='home__Banner'>
				<Banner
					header={dynamicData?.getSolvedH2 ?? 'Problems come and get solved with ease'}
					info={dynamicData?.getSolvedH3 ?? "At HMS we are committed to delivering actionable insights that empower you to make informed decisions and stay ahead of the competition. Trust us to provide you with the intelligence you need to thrive in today's dynamic business landscape."}
					path='/contact-us'
					btnText={dynamicData?.getSolvedButtonText ?? 'Get Started'}
				/>
			</section>
			{/* FAQs */}
			{/* <section
				className='home__Faq'
				ref={ref}
				id='faq-section'>
				<h1>FAQs</h1>
				<div className='home__AccContainer'>
					{AccordionData.map((item) => (
						<Accordion data={item} key={item.id} />
					))}
				</div>
			</section> */}

			{/* Testimonials */}
			<section className='home__Services mb-[10rem]'>
				<div className='home__Services--Top'>
					{/* <p>SERVICES</p> */}
					<h1>
						Testimonials
					</h1>
				</div>
				<div className='home__Services--Bottom'>
					<Swiper
						autoplay={{
							delay: 2000,
							disableOnInteraction: false,
							pauseOnMouseEnter: true
						}}
						// navigation={true}
						// slidesPerView={2}
						breakpoints={{
							640: {
								slidesPerView: 1.5,
								spaceBetween: 20,
							},
							768: {
								slidesPerView: 2,
								spaceBetween: 10,
							},
							1024: {
								slidesPerView: 2,
								spaceBetween: 40,
							},
						}}
						modules={[Navigation, Autoplay, Pagination]}
						spaceBetween={30}
						pagination={{ clickable: true }}
						color='#042E3E'
						style={{
							"--swiper-pagination-color": "#042E3E",
							"--swiper-navigation-color": "#042E3E",
						}}
					>
						{testimonials?.map((item) => (
							<SwiperSlide
								key={item.id}
								className='max-lg:min-h-[40rem] h-[35rem] bg-[#F4F4F4] rounded-[2rem] p-[3rem] flex flex-col gap-[4rem] items-start '>
								<CardTestimonialHome data={item} />
							</SwiperSlide>
						))}
					</Swiper>
				</div>
			</section>
		</main>
	);
});

export default Home;
